import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./VisitStoreDigitally.css";
import LazyLoadImage from "../LazyLoadImage";

const VisitStoreDigitally = ({
  digital_stores = [],
  heading = "",
  loading,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Set breakpoint at 768px
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // Check if the device is mobile or desktop
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 767); // Set breakpoint at 768px
  //   };
  //   handleResize(); // Check on initial load
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // Carousel settings
  const settings = {
    items: 1,
    margin: 10,
    loop: digital_stores.length > 1,
    autoplay: digital_stores.length > 1,
    autoplayTimeout: 6000,
    dots: true,
    nav: false,
  };

  const defaultImage = "https://via.placeholder.com/300";
  const handleBannerClick1 = (type_id, tag_type) => {
    navigate(`/latest-arrivals/${type_id}/${tag_type}`);
  };

  return (
    <section className="store">
      <div className="visit-store-digitally">
        <h2>
          {loading ? <Skeleton width={200} /> : heading}
          {!loading && <Link to="/stores" className="read-more"></Link>}
        </h2>

        <div className="carousel-section">
          {loading ? (
            <Skeleton height={400} count={1} enableAnimation={false} />
          ) : digital_stores.length > 0 ? (
            <OwlCarousel
              className="store-carousel owl-carousel owl-theme"
              {...settings}
            >
              {digital_stores.map((banner, index) => {
                // Choose the correct image based on device type
                const thumbnailSrc = isMobile
                  ? banner.media_url_web_mobile_thumb
                  : banner.media_url_web_desk_thumb;

                const fullImageSrc = isMobile
                  ? banner.media_url_web_mobile
                  : banner.media_url_web_desk;
                console.log(isMobile, "isMobile");
                return (
                  <div
                    key={index + banner.type_id}
                    className="item"
                    onClick={() =>
                      handleBannerClick1(banner.type_id, banner.type)
                    }
                  >
                    {/* <Link
                      to={
                        banner.store_uuid
                          ? `/visit-store/${banner.store_uuid}`
                          : "/stores"
                      }
                      className="banner-btn"
                    > */}
                    <LazyLoadImage
                      baseColor="#ffffff" // Set the base color to white
                      highlightColor="#f8f8f8" // Slightly different shade for visibility
                      enableAnimation={false}
                      thumbnailSrc={thumbnailSrc}
                      fullImageSrc={fullImageSrc}
                      alt={"Store Image"}
                      className="carousel-image"
                      // onError={(e) => (e.target.src = defaultImage)}
                    />
                    {/* </Link> */}
                    {/* Display the heading if available */}
                    {/* {banner.heading_1 && (
                      <div
                        className="banner-heading"
                        dangerouslySetInnerHTML={{ __html: banner.heading_1 }}
                      />
                    )} */}
                  </div>
                );
              })}
            </OwlCarousel>
          ) : (
            <p>No digital stores available.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VisitStoreDigitally;
