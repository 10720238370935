import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "./Modal.css";
import api from "../../api";
import shareVideosLink from "./shareVideosLink";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Modal = ({ isOpen, onClose, videoUrl, videoData }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const api_token = localStorage.getItem("apiToken");
  const [productDetails, setProductDetails] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isProductLoading, setIsProductLoading] = useState(true);
  console.log(videoData, "vvvvvvv");
  useEffect(() => {
    const fetchProductDetails = async () => {
      setIsProductLoading(true);
      if (videoData) {
        const { product_sui, store_uuid } = videoData;
        if (!product_sui || !store_uuid) {
          console.error("Invalid product details");
          return;
        }

        try {
          const response = await api.get("/sku/get_multiple_sku", {
            params: {
              api_token,
              sui_list: product_sui,
              store_uuid,
            },
          });

          if (response.status === 200) {
            setProductDetails(response.data.data.data);
            setImageData(response.data.data.data[0]?.product_url_thumb);
          } else {
            console.error("API request failed with status:", response.status);
          }
        } catch (error) {
          console.error("Error fetching product details:", error);
        } finally {
          setIsProductLoading(false);
        }
      }
    };

    fetchProductDetails();
  }, [videoData, api_token]);

  useEffect(() => {
    const videoElement = videoRef.current;
    let hls;

    if (isOpen && videoUrl && videoElement) {
      setIsLoading(true);
      if (Hls.isSupported() && videoUrl.includes(".m3u8")) {
        hls = new Hls();
        hls.loadSource(videoUrl);
        hls.attachMedia(videoElement);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoElement
            .play()
            .then(() => {
              setIsLoading(false);
            })
            .catch((err) => {
              console.error("Error playing HLS stream:", err);
            });
        });
      } else {
        videoElement.src = videoUrl;
        videoElement
          .play()
          .then(() => {
            setIsLoading(false);
          })
          .catch((err) => {
            console.error("Error playing video:", err);
          });

        videoElement.onloadedmetadata = () => {
          setIsLoading(false);
        };
      }

      const handleLoop = () => {
        videoElement.currentTime = 0;
        videoElement
          .play()
          .catch((err) => console.error("Error playing video:", err));
      };

      videoElement.onended = handleLoop;

      videoElement.ontimeupdate = () => {
        if (videoElement.currentTime >= videoElement.duration - 0.1) {
          handleLoop();
        }
      };

      videoElement.muted = false;
      setIsMuted(false);

      return () => {
        if (hls) {
          hls.destroy();
        }
        videoElement.pause();
        videoElement.onended = null;
        videoElement.ontimeupdate = null;
      };
    }

    if (!isOpen && videoElement) {
      videoElement.muted = true;
      setIsPlaying(false);
      setIsMuted(true);
    }
  }, [isOpen, videoUrl]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = isMuted;
    }
  }, [isMuted]);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.pause();
    } else {
      videoElement
        .play()
        .catch((err) => console.error("Error playing video:", err));
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteUnmute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  const handleShare = async () => {
    const { video_uuid, store_uuid, store_name, description } = videoData;

    try {
      const imagedata = imageData;
      const dynamicLink = await shareVideosLink(
        store_name,
        description,
        video_uuid,
        store_uuid,
        imagedata
      );

      if (navigator.share) {
        await navigator.share({
          url: dynamicLink,
        });
      }
    } catch (error) {
      console.error("Error creating or sharing dynamic link:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay video-frame" onClick={onClose}>
      <div
        className="modal-content video-play-data"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="video-product-container">
          <div className="video-section">
            {/* Video Player */}
            <video ref={videoRef} autoPlay muted playsInline preload="auto" />

            {/* Video Description */}
            {/* <div className="video-description "> */}
            <div className="description-skeleton ">
              {isLoading ||
                (!isProductLoading && (
                  // <Skeleton
                  //   height={70}
                  //   width={200}
                  //   className="description-skeleton"
                  // />
                  <p>
                    {videoData?.video_description || videoData?.description}
                  </p>
                  // ) : (
                ))}
            </div>

            {/* Custom Controls */}
            <div className="custom-controls">
              {isLoading || isProductLoading ? (
                <>
                  <Skeleton
                    height={30}
                    width={30}
                    style={{ display: "inline-block", marginRight: "10px" }}
                    circle={true}
                  />
                  <Skeleton
                    height={30}
                    width={30}
                    style={{ display: "inline-block", marginRight: "10px" }}
                    circle={true}
                  />
                  <Skeleton
                    height={30}
                    width={30}
                    style={{ display: "inline-block" }}
                    circle={true}
                  />
                </>
              ) : (
                <>
                  <button className="control-btn" onClick={onClose}>
                    <img src="/images/close.svg" alt="Close" />
                  </button>

                  <button
                    className="control-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      handleShare();
                    }}
                  >
                    <img src="/images/share.svg" alt="Share video" />
                  </button>

                  <button className="control-btn" onClick={handleMuteUnmute}>
                    <img
                      src={
                        isMuted
                          ? "/images/ic_volume_off.png"
                          : "/images/ic_volume_on.png"
                      }
                      alt={isMuted ? "Unmute" : "Mute"}
                    />
                  </button>
                </>
              )}
            </div>
          </div>

          {/* Product Section */}
          <div className="products-section">
            {isProductLoading ? (
              <Skeleton
                count={productDetails.length}
                height={50}
                width={50}
                style={{ marginTop: "10px" }}
                circle={true}
                className="skeleton-loader"
              />
            ) : (
              productDetails.length > 0 &&
              productDetails.map((product, index) => (
                <a
                  href={`/product/${product.SUI}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="product-item-circle"
                >
                  <img
                    src={product.product_url_thumb}
                    alt={product.product_title}
                    className="product-image-circle"
                  />
                </a>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
