import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useCurrency } from "../../context/CurrencyContext";
import { useAuth } from "../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import api from "../../api";
import LazyLoadImage from "../LazyLoadImage";
import LoadMore from "../LoadMore/LoadMore";
import { isMobile } from "react-device-detect";

const ITEMS_PER_PAGE = 54;
const defaultImage = "https://via.placeholder.com/150";

const ShopFilterProductListing = ({
  storeUuid,
  sortOption,
  sizeOption,
  priceOption,
  colorOption,
  segmentId,
  genderId,
  categoryList,
  sub_category_list,
  productData,
  onClearFilters,
  loading: propLoading,
  style,
  currentPage_data,

  onProductClick,
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedCurrency } = useCurrency();
  const [activeTab, setActiveTab] = useState(false);
  const [loading, setLoading] = useState(propLoading);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loadedItemCount, setLoadedItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loadPage, setLoadPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [isSuiData, setIsSuiData] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentpagenumber") || 0
  );
  const [previousPage, setPreviousPage] = useState(
    sessionStorage.getItem("previouspagenumber") || 0
  );
  const [previewProducts, setPreviewProducts] = useState([]);
  const [dimensions, setDimensions] = useState({ width: "100%" });
  const [isScroll, setIsScroll] = useState(false);
  const productRefs = useRef({});
  useEffect(() => {
    if (isMobile) {
      setDimensions({ width: "100%", height: "275px" });
    }
    if (sessionStorage.getItem("currentpagenumber")) {
      navigate(
        `?page=${sessionStorage.getItem(
          "currentpagenumber"
        )}&&pid=${sessionStorage.getItem("pid")}`,
        {
          replace: true,
        }
      );
    }
  }, [navigate]);
  const isFetchingRef = useRef(false);
  // Fetch products
  const fetchProducts = async (append = false) => {
    // If a request is already in progress, return early to avoid unnecessary calls
    if (isFetchingRef.current) return;
    try {
      isFetchingRef.current = true;
      // Show loading spinner based on append flag
      if (!append) {
        // setLoading(true);
        setLoadedItemCount(0);
      } else {
        setLoadingMore(true);
      }

      // API parameters (same as before)
      const api_token = localStorage.getItem("apiToken");
      const currencyCode = localStorage.getItem("currencyCode");
      const formattedSize = sizeOption.length > 0 ? sizeOption.join(",") : "";
      const formattedColor =
        colorOption.length > 0 ? colorOption.join(",") : "";
      const [minPrice, maxPrice] =
        priceOption &&
        typeof priceOption === "string" &&
        priceOption.includes("-")
          ? priceOption.split("-")
          : ["", ""];

      const sortby =
        sortOption && typeof sortOption === "string" && sortOption
          ? sortOption
          : "";
      const params = {
        api_token,
        page: currentPage,
        size_list: formattedSize,
        color_list: formattedColor,
        price_min: minPrice,
        price_max: maxPrice,
        segment_id: segmentId,
        gender_id: genderId,
        category_list: categoryList,
        currency_code: currencyCode,
        sort_order: sortby,
        store_uuid: "",
        sub_category_list: sub_category_list,
      };

      // Fetch products
      const response = await api.get("/filters/get_skus_for_filter", {
        params,
      });

      if (response.status === 401) {
        logout();
        navigate("/home");
      } else if (response.status === 200) {
        const data = response.data;
        const processedProducts = data.sui_data || [];
        const totalItems = data.total_count;
        const fetchedPage = parseInt(data.current_page); // Use the current_page from the API

        const itempage = Number(fetchedPage * ITEMS_PER_PAGE);

        // Add the current page to each product in the `sui_data` array
        const newProcessedProducts = processedProducts.map((product) => ({
          ...product,
          currentPage: fetchedPage, // Add currentPage field to each product
        }));

        if (!newProcessedProducts.length) {
          // If no products, reset state
          setProducts([]);
          setLoadedItemCount(0);
          setTotalItems(0);
          setIsSuiData(false);
        } else {
          // Update the product list in state
          setProducts((prevProducts) =>
            append
              ? [...prevProducts, ...newProcessedProducts]
              : newProcessedProducts
          );

          // Update loaded items count and total items
          const newLoadedCount = append
            ? loadedItemCount + processedProducts.length
            : processedProducts.length;
          if (sessionStorage.getItem("currentpagenumber") && currentPage > 0) {
            setLoadedItemCount(Number(itempage) + Number(ITEMS_PER_PAGE));
          } else {
            setLoadedItemCount(newLoadedCount);
          }
          setTotalItems(totalItems);
          setIsSuiData(!!processedProducts.length);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
      setError("Failed to fetch products");
    } finally {
      // Hide loading spinner when done
      if (!append) {
        setLoading(false);
      } else {
        setLoadingMore(false);
      }

      // Reset the fetching flag
      isFetchingRef.current = false;
    }
  };

  // Restore scroll position on product update

  // Load preview of products
  const loadPreview = async () => {
    // Decrease page by 1, but don't go below 1
    const targetPage = previousPage > 0 ? previousPage - 1 : 0; // Ensure the page doesn't go below 1

    setLoadingPreview(true);
    setPreviousPage(targetPage); // Store the targetPage as the previous page in the state

    const api_token = localStorage.getItem("apiToken");
    const currencyCode = localStorage.getItem("currencyCode");
    const formattedSize = sizeOption.length > 0 ? sizeOption.join(",") : "";
    const formattedColor = colorOption.length > 0 ? colorOption.join(",") : "";
    const [minPrice, maxPrice] =
      priceOption &&
      typeof priceOption === "string" &&
      priceOption.includes("-")
        ? priceOption.split("-")
        : ["", ""];

    const sortby =
      sortOption && typeof sortOption === "string" && sortOption
        ? sortOption
        : "";

    const params = {
      api_token,
      page: targetPage, // Use targetPage directly for the API call
      size_list: formattedSize,
      color_list: formattedColor,
      price_min: minPrice,
      price_max: maxPrice,
      segment_id: segmentId,
      gender_id: genderId,
      category_list: categoryList,
      currency_code: currencyCode,
      sort_order: sortby,
      store_uuid: "",
      sub_category_list: sub_category_list,
    };

    try {
      const response = await api.get("/filters/get_skus_for_filter", {
        params,
      });

      if (response.status === 200) {
        const data = response.data;
        const processedProducts = data.sui_data || data.sku_data || [];
        const fetchedPage = parseInt(data.current_page, 10);

        // Add currentPage property to each product in the fetched preview
        const updatedProducts = processedProducts.map((product) => ({
          ...product,
          currentPage: fetchedPage, // Assign the fetchedPage to each product
        }));

        // Prepend the new products to the preview list
        setPreviewProducts((prevProducts) => [
          ...updatedProducts, // New products for preview
          ...prevProducts, // Existing preview products
        ]);
      } else {
        setError("Failed to load preview");
      }
    } catch (error) {
      console.error("Error loading preview:", error);
      setError("Failed to load preview");
    } finally {
      setLoadingPreview(false);
    }
  };
  useEffect(() => {
    if (!productData) {
      fetchProducts(false);
    } else {
      const productsData = productData.sui_data || [];
      setProducts(productsData);
      setTotalItems(productData.total_count || 0);
      setLoadedItemCount(productsData.length || 0);
      setLoadedItemCount(productsData.length || 0);
      setLoading(false);
    }
  }, [
    storeUuid,
    sortOption,
    sizeOption,
    priceOption,
    colorOption,
    segmentId,
    genderId,
    categoryList,
    productData,
  ]);
  const params = new URLSearchParams(window.location.search);
  const productIdToScroll = sessionStorage.getItem("pid") || params.get("pid");

  useLayoutEffect(() => {
    // const params = new URLSearchParams(location.search);
    const page = params.get("page");
    setIsScroll(false);
    // const productIdToScroll =
    //   params.get("pid") ||
    //   sessionStorage.getItem("pid");

    // Add a delay before scrolling to the product
    const timer = setTimeout(() => {
      if (productIdToScroll && productRefs.current[productIdToScroll]) {
        productRefs.current[productIdToScroll].scrollIntoView();
        // sessionStorage.removeItem("pid");
      }

      setIsScroll(true);
    }, 100);

    if (page) {
      setCurrentPage(Number(page));
      setLoadedItemCount((Number(page) + 1) * ITEMS_PER_PAGE);
    }

    // Cleanup timer on component unmount or re-render
    return () => clearTimeout(timer);
  }, [productIdToScroll]);

  const loadMoreProducts = () => {
    setActiveTab(true);
    // Increment the page number when 'Load More' is clicked
    setCurrentPage((prevPage) => Number(prevPage) + 1); // Just increment the page, no need to call fetch here
  };
  useEffect(() => {
    console.log(currentPage, "currentPagesdd");
    if (currentPage > 0) {
      setLoadedItemCount((Number(currentPage) + 1) * Number(ITEMS_PER_PAGE)); // Fix the parentheses here
    }
    if (activeTab) {
      fetchProducts(true);
    }
  }, [currentPage]);

  if (error) {
    return <div className="error">{error}</div>;
  }
  // Parse query parameters from the URL
  const urlParams = new URLSearchParams(location.search);
  let page = urlParams.get("page"); // Get the 'page' query parameter

  // Handle cases where 'page' is undefined
  if (page === "undefined" || page === null) {
    page = null; // Normalize 'undefined' or missing values to null
  }

  // Determine if the button should be shown
  const shouldShowButton =
    location.pathname.startsWith("/shop/") && page !== null;
  // if (totalItems <= 0) {
  //   return "No product found";
  // }
  return (
    <div
      className="also-like latest uniquelist"
      style={{ opacity: productIdToScroll ? (isScroll ? "1" : "0") : "1" }}
    >
      {/* Load Preview Button */}

      {shouldShowButton && (
        <div
          className="load-preview-button"
          style={{ textAlign: "center", marginBottom: "0px" }}
        >
          <button
            onClick={loadPreview}
            disabled={loadingPreview || currentPage <= 0} // Disable if on page 1
            className="load-more-button"
            style={{
              display:
                loadingPreview || previousPage <= 0 || currentPage <= 0
                  ? "none"
                  : "inline-block",
            }}
          >
            {loadingPreview ? "Loading Previous..." : "View Previous"}
          </button>
        </div>
      )}

      {/* Display Combined Products */}

      <ul>
        {[
          ...new Map(
            // Combine the old and new products
            [
              ...previewProducts.map((product, index) => [
                product.SUI || product.sui, // Ensure each product is uniquely identified by SUI/sui
                {
                  ...product,
                },
              ]),
              ...products.map((product, index) => [
                product.SUI || product.sui, // Ensure each product is uniquely identified by SUI/sui
                {
                  ...product,
                },
              ]),
            ].map(([key, product]) => [key, product]) // Ensure unique products by their SUI/sui
          ).values(),
        ].map((product, index) => {
          const cacheBuster = new Date().getTime();
          const imageUrlWithCacheBuster = `${
            product.image_url || product.image_url_thumb || defaultImage
          }?cb=${cacheBuster}`;

          const title = isSuiData
            ? product.product_title
            : product.product_title;

          const total_price = isSuiData
            ? product.display_final_price
            : product.display_final_price;
          const strike_price = isSuiData
            ? product.display_total_price
            : product.display_total_price;
          return (
            <li
              ref={(el) =>
                (productRefs.current[product.SUI || product.sui] = el)
              }
              key={index}
              data-pagenumber={(() => {
                switch (true) {
                  case Boolean(product.currentPage):
                    return product.currentPage; // If product.currentPage exists
                  case Boolean(currentPage_data):
                    return currentPage_data; // If currentPage_data exists
                  case Boolean(product.current_page):
                    return product.current_page; // If product.current_page exists
                  default:
                    return 0; // Default value if none of the above are truthy
                }
              })()}
            >
              {/* Dynamically set pagenumber */}
              <Link
                to={`/product/${product.SUI || product.sui}`}
                className="img-box"
                onClick={(e) => {
                  e.preventDefault(); // Prevents the default navigation
                  // Use a switch statement to determine the page number
                  const pageNumber = (() => {
                    switch (true) {
                      case Boolean(product.currentPage):
                        return product.currentPage; // If product.currentPage exists
                      case Boolean(currentPage_data):
                        return currentPage_data; // If currentPage_data exists
                      case Boolean(product.current_page):
                        return product.current_page; // If product.current_page exists
                      default:
                        return 0; // Default value if none of the above are truthy
                    }
                  })();
                  onProductClick &&
                    onProductClick({
                      productId: product.SUI || product.sui,
                      page: pageNumber,
                    });
                }}
              >
                <LazyLoadImage
                  style={{ ...style, ...dimensions }}
                  key={imageUrlWithCacheBuster}
                  thumbnailSrc={product.image_url_thumb}
                  fullImageSrc={product.image_url}
                  alt={title}
                  onError={(e) => (e.target.src = defaultImage)}
                />
              </Link>
              <h4 className="store_name">{product.store_name}</h4>
              <div className="description content">
                <h4>
                  <Link to={`/product/${product.SUI || product.sui}`}>
                    {title?.slice(0, 20)}
                  </Link>
                </h4>

                {product.in_stock === 0 ||
                product.in_stock === "" ||
                product.in_stock === null ? (
                  <span className="sold-out">Sold Out</span>
                ) : (
                  <div className="price-value">
                    {strike_price && strike_price !== total_price && (
                      <>
                        <span className="strike_price">
                          {`${selectedCurrency.symbol || "$"}${strike_price}`}
                        </span>
                        <span className="final_price">
                          {`${selectedCurrency.symbol || "$"}${total_price}`}
                        </span>
                        <span className="discount">
                          {`${
                            product.platform_discount || product.sku_discount
                          }% off`}
                        </span>
                      </>
                    )}
                    {(!strike_price || strike_price === total_price) && (
                      <span className="final_price">
                        {`${selectedCurrency.symbol || "$"}${total_price}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {/* Load More Component */}
      {totalItems > ITEMS_PER_PAGE && (
        <LoadMore
          loadedItemCount={loadedItemCount}
          totalItems={totalItems}
          onLoadMore={loadMoreProducts}
          loadingMore={loadingMore}
        />
      )}
    </div>
  );
};

export default ShopFilterProductListing;
