import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import Skeleton from "react-loading-skeleton"; // Import Skeleton
import { isMobile } from "react-device-detect"; // Import isMobile from react-device-detect

const Breadcrumb = () => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    if (location.state) {
      const { categoryName, subCategoryName } = location.state;
      if (categoryName) setCategoryName(categoryName);
      if (subCategoryName) setSubCategoryName(subCategoryName);
      setLoading(false); // Set loading to false if state is available
    }
  }, [location.state]);

  const handleCategoryClick = () => {
    if (category_id && gender_id && segment_id) {
      const button = document.getElementById("clearButton");
      if (button) {
        button.click();
      }
      navigate(`/shop/${category_id}/${gender_id}/${segment_id}`);
    }
  };

  const handleSubCategoryClick = () => {
    const button = document.getElementById("clearButton");
    if (button) {
      button.click();
    }
  };

  const API_TOKEN = localStorage.getItem("apiToken");
  const fetchSubcategories = async () => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const params = {
        api_token: API_TOKEN,
        segment_id,
        gender_id,
      };

      if (
        category_id !== undefined &&
        category_id !== null &&
        category_id !== ""
      ) {
        params.category_id = category_id;
      }

      if (
        sub_category_id !== undefined &&
        sub_category_id !== null &&
        sub_category_id !== ""
      ) {
        params.sub_category_id = sub_category_id;
      } else {
        params.sub_category_id = "";
      }

      const response = await api.get(`/sku/get_subcategories`, { params });

      if (response.data.status === 200) {
        setSubCategoryName(response?.data?.data[0]?.sub_category);
        setCategoryName(response?.data?.data[0]?.category);
        setLoading(false); // Set loading to false once data is fetched
      }
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setLoading(false); // Stop loading in case of an error
    }
  };

  useEffect(() => {
    if (segment_id && category_id && gender_id) {
      fetchSubcategories();
    }
  }, [segment_id, category_id, gender_id]);

  return (
    <div className="breadcrumb">
      <div className="container">
        <ul>
          <li>
            {/* Conditional Skeleton for category name li */}
            {loading && isMobile ? (
              <Skeleton
                width={200}
                baseColor="##ffffff"
                highlightColor="#f8f8f8"
                enableAnimation={false}
              />
            ) : (
              category_id &&
              categoryName && (
                <>
                  <span
                    onClick={handleCategoryClick}
                    style={{ cursor: "pointer" }}
                  >
                    {categoryName}
                  </span>
                  {sub_category_id && (
                    <span style={{ marginLeft: "5px" }}>&gt;</span>
                  )}
                </>
              )
            )}
          </li>

          <li>
            {/* Directly render subCategoryName, no skeleton needed for this li */}
            {sub_category_id && subCategoryName && (
              <span>{subCategoryName}</span>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
