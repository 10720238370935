// ProductDetails.js
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import $ from "jquery";
import ProductOrderDetails from "./ProductOrderDetails";
import ProductPolicy from "./ProductPolicy";
import SizeGuide from "./SizeGuide";
import RelatedProducts from "./RelatedProducts";
import api from "../../api";
import { useCart } from "../../context/CartContext";
import { useAuth } from "../../context/AuthContext";
import Skeleton from "react-loading-skeleton";
import ProductDetailsImageBlock from "./ProductDetailsImageBlock";
import ProductIcons from "./ProductIcons";
import { isMobile } from "react-device-detect";
import {
  ToastNotification,
  notifySuccess,
  notifyError,
} from "../toastNotification/ToastNotification";

const ProductDetails = () => {
  const toastRef = useRef();
  const { pathname } = useLocation();
  // useEffect(() => {
  //   $("html, body").scrollTop(0);
  //   $("#root").scrollTop(0);
  // }, [pathname]);
  const { sui } = useParams();
  const { cartCount, addItemToCart } = useCart();
  const { apiToken, deviceToken, logout } = useAuth();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [storeUuid, setStoreUuid] = useState(null);
  const [activeCollapse, setActiveCollapse] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const [videoList, setVideoList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [sizeChartList, setSizeChartList] = useState([]);
  const [chartOptionName, setChartOptionName] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [isWishlist, setIsWishlist] = useState(false);
  const [systemName, SetsystemName] = useState(false);
  const [currencysybmol, setCurrencysybmol] = useState(false);
  const [inStock, setInStock] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(false);
  const [originalPrice, setOriginalPrice] = useState(false);
  const [discountPercentageOff, setDiscountPercentageOff] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "525px" : "745px",
  });

  useEffect(() => {
    const newHeight = isMobile ? "525px" : "745px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const api_token = localStorage.getItem("apiToken");
        const response = await api.get(`/sku/get_sku_details`, {
          params: {
            api_token: api_token,
            sui,
            is_private: 0,
          },
        });
        const data = response.data;
        console.log(data, "trst");
        if (data?.status === 200) {
          const productDetails = data?.sui_data[0];
          setStoreUuid(data?.store_details?.store_uuid);

          if (
            productDetails?.video_data &&
            productDetails?.video_data?.length > 0
          ) {
            setVideoList(productDetails?.video_data);
          }

          if (productDetails?.sku_details?.image_data?.image_data) {
            convertStringIntoArray(
              productDetails?.sku_details?.image_data?.image_data
            );
          }

          const original_price = productDetails?.sku_price?.price;
          const discountedPrice =
            productDetails?.sku_price?.price_post_discount;
          const currency = productDetails?.sku_price?.symbol;
          const inStock = productDetails?.sku_details?.in_stock;
          if (currency) {
            setCurrencysybmol(currency);
          }
          if (original_price) {
            setOriginalPrice(original_price);
          }
          if (discountedPrice) {
            setDiscountedPrice(discountedPrice);
          }
          if (inStock) {
            setInStock(inStock);
          }
          if (original_price === discountedPrice) {
            if (
              inStock === "0" ||
              productDetails?.sku_details?.is_private === "1"
            ) {
              const discountPercentageOff =
                original_price && discountedPrice
                  ? Math.round(
                      ((original_price - discountedPrice) / original_price) *
                        100
                    )
                  : null;
              setDiscountPercentageOff(discountPercentageOff);
            } else {
              const discountPercentageOff =
                original_price && discountedPrice
                  ? Math.round(
                      ((original_price - discountedPrice) / original_price) *
                        100
                    )
                  : null;
              setDiscountPercentageOff(discountPercentageOff);
            }
          } else {
            const discountPercentageOff =
              original_price && discountedPrice
                ? Math.round(
                    ((original_price - discountedPrice) / original_price) * 100
                  )
                : null;
            setDiscountPercentageOff(discountPercentageOff);
          }

          let normalizeColorList = [];

          if (
            productDetails.sku_colors &&
            productDetails.sku_colors?.length > 0
          ) {
            normalizeColorList = productDetails.sku_colors;
          } else if (
            productDetails.product_variants &&
            productDetails.product_variants.color_list
          ) {
            normalizeColorList =
              productDetails.product_variants.color_list?.map((colorObj) => ({
                ...colorObj,
                name: colorObj.color,
              }));
          }

          setColorList(normalizeColorList);
          let normalizedSizeList = [];
          if (
            productDetails.sku_sizes &&
            productDetails.sku_sizes?.length > 0
          ) {
            normalizedSizeList = productDetails.sku_sizes;
          } else if (
            productDetails.product_variants &&
            productDetails.product_variants?.sizes &&
            productDetails.product_variants?.sizes?.length > 0
          ) {
            normalizedSizeList =
              productDetails.product_variants?.sizes[0].sizes?.map(
                (sizeObj) => ({
                  ...sizeObj,
                  name: sizeObj.size,
                })
              );
          }
          console.log(normalizedSizeList, "normalizedSizeList");
          setSizeList(normalizedSizeList);

          const skuDetails_data = productDetails?.sku_details;
          const system_name = skuDetails_data?.product_image_name
            ? skuDetails_data?.product_image_name
            : "";
          SetsystemName(system_name);
          setIsWishlist(skuDetails_data?.is_wishlist);
          setIsFavorite(skuDetails_data?.is_favorite);
          if (productDetails?.sku_details?.size_chart_list) {
            setSizeChartList(productDetails?.sku_details?.size_chart_list);
          }
          if (productDetails?.sku_details?.size_chart_type) {
            setChartOptionName(productDetails?.sku_details?.size_chart_type);
          }
          setAdditionalData(productDetails?.sku_details?.policy);
        } else if (response.status === 401) {
          logout();
          navigate("/home");
        }
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const productDetails = response.data.sui_data[0];
        setProduct(productDetails);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [sui]);
  useEffect(() => {
    if (sizeList?.length > 0) {
      const defaultSize =
        sizeList.find((size) => size.name === "M") || sizeList[0];
      setSelectedSize(defaultSize);
    }
  }, [sizeList]);
  useEffect(() => {
    if (colorList?.length > 0) {
      const defaultcolor = colorList[0];
      setSelectedColor(defaultcolor);
    }
  }, [sizeList]);

  const convertStringIntoArray = (data) => {};

  const setAdditionalData = (policy) => {};

  const getUserAddressList = (param) => {};

  const getRelatedSku = () => {};

  const handleCollapseToggle = (index) => {
    setActiveCollapse(activeCollapse === index ? null : index);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleDropdownChange = (event) => {
    const selectedColor1 = colorList.find(
      (color) => color.color_id === parseInt(event.target.value, 10)
    );
    setSelectedColor(selectedColor1);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };
  const handleDropdownSizeChange = (e) => {
    const newSizeId = e.target.value;
    const newSize = sizeList.find(
      (size) => size.size_id === parseInt(newSizeId, 10)
    );
    setSelectedSize(newSize);
  };

  const addToCart = async () => {
    const api_token = localStorage.getItem("apiToken");
    const currencyId = localStorage.getItem("currencyId");
    const cart_id = localStorage.getItem("cart_id");
    const order_num = localStorage.getItem("order_num");
    const item_count = localStorage.getItem("item_count");

    const payload = {
      api_token,
      cart_id,
      color_id: selectedColor?.color_id?.toString() || "",
      currency_id: currencyId?.toString() || "",
      gift_list_id: "",
      gift_message: "",
      order_num,
      quantity: "1",
      receipient_uuid: "",
      size_id: selectedSize?.size_id?.toString() || "",
      sui,
    };

    try {
      const response = await api.post("/cart/add_item_to_cart", payload);

      if (response.data.cart_data.status === 401) {
        logout();
        navigate("/home");
      } else if (response.data.cart_data.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      addItemToCart();

      navigate("/cart");
    } catch (error) {
      console.error("Error adding item to cart:", error);
    }
  };

  // Function to check if all sizes are sold out
  const checkIfAllSizesSoldOut = () => {
    return sizeList.every((size) => size?.quantity === 0);
  };

  // Check sizes when the component mounts or when sizeList changes
  useEffect(() => {
    const allSoldOut = checkIfAllSizesSoldOut();
    setInStock(!allSoldOut);
  }, [sizeList]);
  if (error) {
    return <p>Error: {error}</p>;
  }

  if (loading) {
    return (
      <div className="main-content product-details">
        <div className="order-details">
          <div className="container">
            <div className="product-container">
              <div className="image-block">
                <div className="product-gallery">
                  <div
                    className="thumbnails"
                    style={{
                      display: "flex", // Use flexbox for layout
                      flexDirection: isMobile ? "row" : "column", // Horizontal for mobile, vertical for larger screens
                      alignItems: "center", // Center items along the cross-axis
                      justifyContent: isMobile ? "space-around" : "flex-start", // Adjust spacing
                      overflowX: isMobile ? "scroll" : "visible", // Allow scrolling for horizontal layout on mobile
                      gap: "10px", // Space between thumbnails
                      minHeight: isMobile ? "60px" : "90px",
                    }}
                  >
                    {[...Array(5)].map((_, index) => (
                      <Skeleton
                        key={index}
                        style={{
                          flex: "0 0 auto", // Prevent shrinking and enable proper spacing
                          marginBottom: isMobile ? "0" : "10px", // Margin for vertical layout
                          minHeight: isMobile ? "90px" : "90px",
                        }}
                        height={isMobile ? 60 : 120} // Adjust height for mobile and desktop
                        width={isMobile ? 60 : 80} // Adjust width for mobile and desktop
                      />
                    ))}
                  </div>
                  <div className="large-image">
                    <Skeleton style={dimensions} width={525} />
                  </div>
                </div>
              </div>
              <div className="order-content">
                <Skeleton
                  height={30}
                  width={505}
                  style={{ marginBottom: "15px" }}
                />
                <Skeleton height={100} width={505} />

                <div
                  className="price-details"
                  height={80}
                  width={505}
                  style={{ marginTop: "25px" }}
                >
                  <div className="price-cont" style={{ marginRight: "10px" }}>
                    <Skeleton height={50} width={180} />
                  </div>
                  <div
                    className="product-icons"
                    style={{ marginRight: "10px" }}
                  >
                    <div className="icons-row">
                      <ul>
                        <li>
                          <Skeleton circle height={40} width={40} />
                        </li>
                        <li>
                          <Skeleton circle height={40} width={40} />
                        </li>
                      </ul>
                      <div className="store-btn">
                        <ul>
                          <li>
                            <Skeleton width={150} height={40} />
                          </li>
                          <li>
                            <Skeleton width={150} height={40} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="color-variation">
                  <ul>
                    <li>
                      <Skeleton height={25} width={120} />
                    </li>
                    <li>
                      <Skeleton height={25} width={120} />
                    </li>
                  </ul>
                </div>
                <div className="size-variation">
                  <Skeleton height={70} width={505} />
                </div>

                <SizeGuide
                  sizeguide={[]}
                  sizeguideChart={[]}
                  sizeguideType=""
                />

                <div
                  className="product-cta add-cart"
                  style={{ marginTop: "25px" }}
                >
                  <Skeleton height={65} width={505} />
                </div>

                <div
                  className="product-description"
                  style={{ height: "65px", width: "505px", marginTop: "25px" }}
                >
                  <div className="content">
                    <Skeleton
                      count={4}
                      height={35}
                      style={{ marginTop: "10px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <RelatedProducts relatedProducts={[]} />
      </div>
    );
  }

  // const { sku_details } = product;
  // const {
  //   description,
  //   size_chart_list = [],
  //   size_guide_chart = [],
  //   size_chart_type = "",
  //   policy = [],
  // } = sku_details || {};
  const handleSizeSelectToggle = () => {
    const sizeSelect = document.getElementById("size-select");
    const sizeGuide = document.getElementById("size-guide");

    if (sizeSelect) {
      sizeSelect.style.display =
        sizeSelect.style.display === "none" ? "block" : "none";
    }

    if (sizeGuide) {
      sizeGuide.style.display =
        sizeGuide.style.display === "none" ? "block" : "none";
    }
    setIsToggled((prevState) => !prevState);
  };
  console.log(inStock, "inStock");
  return (
    <>
      <div className="main-content product-details">
        <div className="order-details">
          <div className="container">
            <div className="product-container">
              <ProductDetailsImageBlock
                imageData={product?.sku_details?.image_data.image_data || []}
                storeUuid={storeUuid}
                videoData={videoList}
                isWishlist={isWishlist}
                isFavorite={isFavorite}
                sui={sui}
                color_id={selectedColor || ""}
                size_id={selectedSize || ""}
                systemName={systemName}
              />
              <div className="order-content">
                <h3 className="product-name">
                  {product?.sku_details?.product_title}{" "}
                </h3>
                <p
                  className="short-description"
                  dangerouslySetInnerHTML={{
                    __html: product?.sku_details?.description,
                  }}
                ></p>
                <div className="price-details">
                  <div className="price-cont">
                    {inStock === 0 ||
                    inStock === "" ||
                    inStock === null ||
                    !inStock ? (
                      <div className="product-cta add-cart sold">
                        <span className="sold-out">Sold Out</span>
                      </div>
                    ) : (
                      <>
                        {originalPrice !== discountedPrice ? (
                          <>
                            <span className="regular-price">
                              {currencysybmol}
                              {originalPrice}
                            </span>
                            <span className="sale-price">
                              {currencysybmol}
                              {discountedPrice}
                            </span>
                            {discountPercentageOff && (
                              <span className="discount-value">
                                {`${discountPercentageOff}%`}
                              </span>
                            )}
                          </>
                        ) : (
                          <span className="sale-price">
                            {currencysybmol}
                            {discountedPrice}
                          </span>
                        )}
                      </>
                    )}
                  </div>

                  <ProductIcons
                    isWishlist={isWishlist}
                    isFavorite={isFavorite}
                    videoData={videoList}
                    storeUuid={storeUuid}
                    itemId={sui}
                    color_id={selectedColor || ""}
                    size_id={selectedSize || ""}
                    systemName={systemName}
                    imageData={
                      product?.sku_details?.image_data.image_data[0] || []
                    }
                    description={product?.sku_details?.description}
                    title={product?.sku_details?.product_title}
                  />
                </div>
                {colorList?.length > 1 ? (
                  <div className="color-variation" id="color-variation">
                    <h4>Color</h4>
                    <select
                      onChange={handleDropdownChange}
                      value={selectedColor ? selectedColor.color_id : ""}
                      className="color-dropdown"
                    >
                      {colorList?.map((color) => (
                        <option key={color.color_id} value={color.color_id}>
                          {color.name}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="color-variation" id="color-variation-ul">
                    {colorList?.length > 0 && (
                      <>
                        <h4>Color -</h4>
                        <ul className="color-list">
                          {colorList?.map((color) => (
                            <li
                              key={color.color_id}
                              data-color_id={color.color_id}
                            >
                              <a
                                href="#"
                                className={
                                  selectedColor === color ? "active" : ""
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleColorChange(color);
                                }}
                              >
                                {color.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                )}

                {sizeList?.length > 1 ? (
                  <div className="size-variation" id="size-variation">
                    <h4>
                      Size
                      {product?.sku_details?.size_guide_chart?.length === 0 ? (
                        <div style={{ display: "none" }}>
                          No size data available
                        </div>
                      ) : (
                        <a
                          href="#"
                          id="select-size"
                          onClick={handleSizeSelectToggle}
                          style={{
                            textDecoration: "none",
                            color: isToggled ? "black" : "inherit",
                          }}
                        >
                          {isToggled ? "✖️" : "Size Guide"}
                        </a>
                      )}
                    </h4>
                    <select
                      id="size-selector"
                      onChange={handleDropdownSizeChange}
                      value={selectedSize ? selectedSize.size_id : ""}
                      className="color-dropdown"
                    >
                      {sizeList?.map((size) => (
                        <option
                          key={size.size_id}
                          value={size.size_id}
                          disabled={size.quantity === 0}
                        >
                          {size.name}{" "}
                          {size.quantity === 0 && " \u00A0 (Sold Out)"}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div className="color-variation" id="color-variation-ul">
                    {colorList?.length > 0 && (
                      <>
                        <h4>Size -</h4>
                        <ul className="color-list">
                          {sizeList?.map((size) => (
                            <li key={size.size_id} data-size_id={size.size_id}>
                              <a
                                href="#"
                                className={`${
                                  selectedSize === size ? "active" : ""
                                } ${size.quantity === 0 ? "sold-out" : ""}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (size.quantity > 0) {
                                    handleSizeChange(size);
                                  }
                                }}
                              >
                                {size.name} {size.quantity === 0 && "(Sold)"}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                )}

                <SizeGuide
                  sizeguide={product?.sku_details?.size_chart_list}
                  sizeguideChart={product?.sku_details?.size_guide_chart}
                  sizeguideType={product?.sku_details?.size_chart_type}
                />
                {
                  // console.log(  {product.in_stock === 0 && (
                  //   <span className="sold-out">Sold Out</span>
                  // )})
                  console.log(inStock, "instock")
                }
                {/* {inStock === 0 || inStock === "" || inStock === null ? (
                <div className="product-cta add-cart sold">
                  {" "}
                  <span className="sold-out">Sold Out</span>
                </div>
              ) : (
                <div className="product-cta add-cart">
                  <button onClick={addToCart} className="add-to-cart-button">
                    Add to shopping bag
                  </button>
                </div>
              )} */}
                <div className="product-cta add-cart">
                  {!inStock ? (
                    <button className="add-to-cart-button sold" disabled>
                      Add to shopping bag
                    </button>
                  ) : (
                    <button onClick={addToCart} className="add-to-cart-button">
                      Add to shopping bag
                    </button>
                  )}
                </div>
                {/* <div className="product-cta add-cart">
                {inStock === 0 || inStock === "" || inStock === null ? (
                  <button className="add-to-cart-button sold" disabled>
                    Add to shopping bag
                  </button>
                ) : (
                  <button onClick={addToCart} className="add-to-cart-button">
                    Add to shopping bag
                  </button>
                )}
              </div> */}

                <div className="product-details-collapse product-option">
                  <ProductPolicy
                    policies={product?.sku_details?.policy}
                    discription={product?.sku_details?.product_title}
                    product_details={product?.sku_details?.description}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="latest-arrivals">
          <div className="related-products">
            <RelatedProducts storeUuid={storeUuid} sui={sui} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
