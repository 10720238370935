import React, { useState, useEffect } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";
const OrderProcessing = ({
  CheckoutorderData,
  showModal,
  handleCloseModal,
}) => {
  const navigate = useNavigate();

  const { logout } = useAuth();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentId, setPaymentId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [gateway, setGateway] = useState("");
  const [gatewayLogo, setGatewayLogo] = useState("");
  const api_token = localStorage.getItem("apiToken");
  const order_num = localStorage.getItem("order_num");

  const processOrder = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post(
        "/payment/create_order",
        CheckoutorderData
      );
      if (response.data.status === 200) {
        const orderData = response.data.order_data;

        setPaymentId(orderData.id);
        setOrderId(orderData.id);
        const gatewayDetails = response.data.gateway_details;

        if (gatewayDetails.gateway_provider.toLowerCase() === "stripe") {
          setClientSecret(orderData.paymentIntent);
          setPublishableKey(orderData.publishableKey);
          setGateway(gatewayDetails.gateway_provider);
          setGatewayLogo(gatewayDetails.gateway_seashels_logo);
        }
      } else if (response.data.status === 401) {
        logout();
        navigate("/home");
        return;
      } else if (response.data.status === 403) {
        setError("Coupon code expired. Kindly remove it to continue.");
      } else {
        setError("Please try again later.");
      }
    } catch (error) {
      setError("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (publishableKey) {
      const initializeStripe = async () => {
        const stripe = await loadStripe(publishableKey);
        setStripePromise(stripe);
      };
      initializeStripe();
    }
  }, [publishableKey]);

  useEffect(() => {
    if (CheckoutorderData) {
      processOrder();
    }
  }, [CheckoutorderData]);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} className="payment-modal-subelement">
          <StripePaymentForm
            clientSecret={clientSecret}
            apiToken={CheckoutorderData.api_token}
            couponOwnerId={CheckoutorderData.coupon_owner_id}
            gateway={CheckoutorderData.gateway}
            orderNum={CheckoutorderData.order_num}
            shipmentRateObj={CheckoutorderData.ship_object_id}
            isOpen={showModal}
            onClose={handleCloseModal}
          />
        </Elements>
      )}
    </>
  );
};

export default OrderProcessing;
