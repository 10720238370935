import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

// Async thunk for fetching store data
export const fetchStoreData = createAsyncThunk(
  "shop/fetchStoreData",
  async ({ apiEndpoint, params }, { rejectWithValue }) => {
    try {
      const response = await api.get(apiEndpoint, { params });
      if (response.status === 401) {
        throw new Error("Unauthorized");
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const shopSlice = createSlice({
  name: "shop",
  initialState: {
    storeData: null,
    loading: false,
    error: null,
    currentPage: 1, // Tracks current page
    scrollPosition: 0, // Tracks scroll position
  },
  reducers: {
    clearShopState: (state) => {
      state.storeData = null;
      state.loading = false;
      state.error = null;
      state.currentPage = 1;
      state.scrollPosition = 0;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoreData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStoreData.fulfilled, (state, action) => {
        state.loading = false;
        if (state.currentPage > 1 && state.storeData) {
          // Append new data for pagination
          state.storeData = [...state.storeData, ...action.payload];
        } else {
          state.storeData = action.payload;
        }
      })
      .addCase(fetchStoreData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearShopState, setCurrentPage, setScrollPosition } =
  shopSlice.actions;
export default shopSlice.reducer;
