import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname, location.search, "Navigating to:");

    // Use jQuery to reset scroll for both `html, body` and specific containers
    $("html, body").scrollTop(0);
    $("#root").scrollTop(0);

    // If additional scroll containers exist, reset their scroll position
    $(".main-content").scrollTop(0);
  }, [location.pathname, location.search]); // React on both path and query parameter changes

  return null;
};

export default ScrollToTop;
