import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./LatestArrivals.css";
import LazyLoadImage from "../LazyLoadImage";
import { isMobile } from "react-device-detect";

const LatestArrivals = ({ products = {}, loading }) => {
  const navigate = useNavigate();

  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "212px" : "375px", // Responsive height
  });

  useEffect(() => {
    // Update dimensions dynamically when the component is mounted
    const newHeight = isMobile ? "212px" : "375px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);

  const settings = {
    items: 5,
    margin: 10,
    loop: true,
    autoplay: false,
    autoplayTimeout: 4000,
    dots: false,
    responsive: {
      0: { items: 2.2 },
      768: { items: 3 },
      1024: { items: 4 },
      1200: { items: 5 },
    },
  };

  const carouselItems = products.data?.carousel || [];
  const heading = products.heading || "Latest Arrivals";

  return (
    <section className="latest">
      <div className="container">
        <h2>
          {loading ? <Skeleton width={200} /> : heading}
          {!loading && carouselItems.length > 0 && (
            <Link
              to={`/latest-arrivals/${carouselItems[0]?.tag_id || ""}/${
                carouselItems[0]?.tag_type || ""
              }`}
              className="read-more"
            ></Link>
          )}
        </h2>

        {loading ? (
          <div className="carousel-skeleton social-carousel">
            {[...Array(6)].map((_, index) => (
              <div key={index} className="item">
                <div className="img-box">
                  <Skeleton height={dimensions.height} width={230} />
                </div>
                <div className="content">
                  <Skeleton
                    width={100}
                    height={30}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton width={150} height={40} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className="social-carousel"
            //  {...settings}
          >
            {carouselItems.length > 0 ? (
              carouselItems.map((product, index) => (
                <div key={index} className="item">
                  <div className="img-box" style={dimensions}>
                    <Link to={`/product/${product.sui}`}>
                      <LazyLoadImage
                        thumbnailSrc={
                          product.image_url_thumb ||
                          "https://via.placeholder.com/300"
                        }
                        fullImageSrc={
                          product.image_url || "https://via.placeholder.com/300"
                        }
                        alt={product.product_title}
                        onError={(e) =>
                          (e.target.src = "https://via.placeholder.com/300")
                        }
                      />
                      {product.in_stock === 0 && (
                        <span className="sold-out">Sold Out</span>
                      )}
                    </Link>
                  </div>
                  <div className="content">
                    <h4>
                      <Link to={`/visit-store/${product.store_uuid}`}>
                        {product.store_name}
                      </Link>
                    </h4>
                    <div className="price">
                      <span>
                        <Link to={`/product/${product.sui}`}>
                          {isMobile
                            ? `${product.product_title.slice(0, 20)}`
                            : product.product_title.slice(0, 20)}
                        </Link>
                      </span>
                      <div className="price-content-sec">
                        {product.display_total_price !==
                          product.display_final_price && (
                          <span className="strike_price">
                            {`${product.display_currency_symbol}${product.display_total_price}`}
                          </span>
                        )}
                        <span className="final_price">
                          {`${product.display_currency_symbol}${product.display_final_price}`}
                        </span>
                        {product.display_total_price !==
                          product.display_final_price && (
                          <span className="discount">
                            {`${product.display_discount}% off`}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No products available.</p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default LatestArrivals;
