import React, { useState } from "react";

const LazyLoadImage = ({ thumbnailSrc, fullImageSrc, alt, onError, style }) => {
  const [imageSrc, setImageSrc] = useState(thumbnailSrc);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageSrc(fullImageSrc);
    setIsLoaded(true);
  };

  return (
    <img
      src={imageSrc}
      alt={alt}
      onLoad={handleImageLoad}
      onError={onError}
      style={style}
      className={isLoaded ? "loaded" : "loading"}
    />
  );
};

export default LazyLoadImage;
