import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import {
  fetchStoreData,
  clearShopState,
  setScrollPosition,
} from "../../redux/slices/shopSlice";
import Breadcrumb from "../breadCrumb/Breadcrumb";
import ShopFilter from "./ShopFilter";
import MultiCategoryShopFilter from "./MultiCategoryShopFilter";

const Shop = ({
  apiEndpoint = "/filters/get_sub_categories_list",
  pageTitle = "",
}) => {
  const { category_id, gender_id, segment_id, sub_category_id } = useParams();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const scrollPositions = JSON.parse(
    localStorage.getItem("scrollPositions") || "{}"
  );

  const dispatch = useDispatch();
  const { storeData, loading, error, scrollPosition } = useSelector(
    (state) => state.shop
  );

  const categoryId = location.state?.categoryId || category_id;
  const subCategoryId = location.state?.subCategoryId || sub_category_id;

  const isCategoryIdArray = Array.isArray(categoryId) && categoryId.length > 0;
  const isSubCategoryIdArray =
    Array.isArray(subCategoryId) && subCategoryId.length > 0;

  // Fetch data when component mounts
  useEffect(() => {
    const api_token = localStorage.getItem("apiToken");
    dispatch(
      fetchStoreData({
        apiEndpoint,
        params: {
          api_token,
          segment_id: segment_id || "",
          gender_id: gender_id || 2,
          category_id: categoryId || "",
          page: 0,
        },
      })
    );

    return () => {
      dispatch(clearShopState()); // Cleanup on unmount
    };
  }, [apiEndpoint, segment_id, gender_id, categoryId]);

  // Handle unauthorized errors
  useEffect(() => {
    if (error === "Unauthorized") {
      logout();
      navigate("/home");
    }
  }, [error]);

  const handleProductClick = (productId, page) => {
    console.log(productId, "productId");
    console.log("currentpagenumber", productId.page);
    console.log("pid", productId.productId);
    // return;
    // Capture the current scroll position
    const scrollPosition = document.documentElement.scrollTop;

    // Store the scroll position in sessionStorage
    sessionStorage.setItem("scrollPosition", scrollPosition);
    sessionStorage.setItem("currentpagenumber", productId.page);

    sessionStorage.setItem("pid", productId.productId);
    console.log(sessionStorage.getItem("pid"), "pid");
    // Only store the current page number if it is defined and greater than 0
    if (productId.page > 0 && productId.page !== undefined) {
      sessionStorage.setItem("currentpagenumber", productId.page);
      sessionStorage.setItem("previouspagenumber", productId.page);
      sessionStorage.setItem("pid", productId.productId);
    }

    // Navigate to the product page with the provided productId
    navigate(`/product/${productId.productId}`);
  };
  // useEffect(() => {
  //   if (sessionStorage.getItem("currentpagenumber")) {
  //     navigate(
  //       `?page=${sessionStorage.getItem(
  //         "currentpagenumber"
  //       )}&&pid=${sessionStorage.getItem("pid")}`,
  //       {
  //         replace: true,
  //       }
  //     );
  //   }
  // }, [navigate]);
  return (
    <div className="main-content not-home shop-section-data">
      {isCategoryIdArray || isSubCategoryIdArray ? (
        <MultiCategoryShopFilter
          categoryIds={isCategoryIdArray ? categoryId : [category_id]}
          subCategoryIds={
            isSubCategoryIdArray ? subCategoryId : [sub_category_id]
          }
          SubCategoryList_data={storeData}
          isLoading={loading}
        />
      ) : (
        <>
          <Breadcrumb />
          <ShopFilter
            SubCategoryList_data={storeData}
            isLoading={loading}
            onProductClick={handleProductClick} // Pass click handler
          />
        </>
      )}
    </div>
  );
};

export default Shop;
