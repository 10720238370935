import React, { useState, useEffect, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./ProductDetailsImageBlock.css";
import { isMobile } from "react-device-detect";

const ProductDetailsImageBlock = ({
  imageData = [], // Default value to avoid undefined errors
  storeUuid,
  videoData = [],
  isWishlist = false,
  isFavorite = false,
  sui = "",
  color_id = "",
  size_id = "",
  systemName = "",
}) => {
  const [mainImage, setMainImage] = useState("");
  const [thumbImage, setThumbImage] = useState([]);
  const [activeImageOrder, setActiveImageOrder] = useState(null);
  const [isZooming, setIsZooming] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(1);
  const transformWrapperRef = useRef(null);
  const imageCacheRef = useRef({});
  const [dimensions, setDimensions] = useState({
    width: "100%",
    height: isMobile ? "445px" : "745px",
  });

  const maxZoomLevel = 5;
  const zoomStep = 1;

  useEffect(() => {
    const newHeight = isMobile ? "445px" : "745px";
    setDimensions({ width: "100%", minHeight: newHeight });
  }, []);

  useEffect(() => {
    if (Array.isArray(imageData) && imageData.length > 0) {
      // Preload main images
      imageData.forEach((img) => {
        const imgLarge = new Image();
        imgLarge.src = img?.image_url;
        imageCacheRef.current[img?.image_url] = imgLarge;
      });

      // Set initial main image and active order
      setMainImage(imageData[0]?.image_url);
      setActiveImageOrder(imageData[0].image_order);
      localStorage.setItem("mainImage", imageData[0]?.image_url);

      // Process thumbnails
      const thumbnails = imageData.map((img) => {
        const imgThumbnail = new Image();
        imgThumbnail.src = img.image_url_thumb;
        imageCacheRef.current[img.image_url_thumb] = imgThumbnail;
        return {
          image_url: img.image_url,
          image_url_thumb: img.image_url_thumb,
          image_order: img.image_order,
        };
      });

      setThumbImage(thumbnails);
      localStorage.setItem("thumbnailImages", JSON.stringify(thumbnails));
    } else {
      console.error("Invalid imageData:", imageData);
    }
  }, [imageData]);

  const resetZoom = () => {
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform();
      setCurrentZoom(1);
      setIsZooming(false);
    }
  };

  const handleThumbnailClick = (url, imageOrder) => {
    setMainImage(url);
    setActiveImageOrder(imageOrder);
    localStorage.setItem("mainImage", url);
    resetZoom();
  };

  const isActiveThumbnail = (imageOrder) => activeImageOrder === imageOrder;

  const handleZoomToggle = (zoomIn, zoomOut, resetTransform) => {
    if (currentZoom >= maxZoomLevel) {
      if (currentZoom > 1) {
        zoomOut();
        setCurrentZoom((prevZoom) => Math.max(prevZoom - zoomStep, 1));
      } else {
        resetTransform();
        setIsZooming(false);
      }
    } else {
      zoomIn();
      setCurrentZoom((prevZoom) => Math.min(prevZoom + zoomStep, maxZoomLevel));
      setIsZooming(true);
    }
  };

  const handleNextThumbnail = () => {
    const currentIndex = thumbImage.findIndex(
      (img) => img.image_order === activeImageOrder
    );
    const nextIndex = (currentIndex + 1) % thumbImage.length;
    handleThumbnailClick(
      thumbImage[nextIndex].image_url,
      thumbImage[nextIndex].image_order
    );
  };

  const handlePrevThumbnail = () => {
    const currentIndex = thumbImage.findIndex(
      (img) => img.image_order === activeImageOrder
    );
    const prevIndex =
      (currentIndex - 1 + thumbImage.length) % thumbImage.length;
    handleThumbnailClick(
      thumbImage[prevIndex].image_url,
      thumbImage[prevIndex].image_order
    );
  };

  return (
    <div className="image-block">
      <div className="product-gallery">
        {/* Thumbnails Section */}
        <div className="thumbnails">
          {thumbImage?.map((img, index) => (
            <div
              key={index}
              className={`thumbnail ${
                isActiveThumbnail(img.image_order) ? "active" : ""
              }`}
              onClick={() =>
                handleThumbnailClick(img.image_url, img.image_order)
              }
              style={{ minHeight: isMobile ? "60px" : "90px" }}
            >
              <img
                src={img.image_url_thumb}
                alt={`Thumbnail ${index + 1}`}
                style={{ minHeight: isMobile ? "60px" : "90px" }}
              />
            </div>
          ))}
        </div>

        {/* Main Image Section */}
        <div
          className="large-image"
          onMouseLeave={resetZoom}
          style={dimensions}
        >
          <TransformWrapper
            ref={transformWrapperRef}
            initialScale={1}
            minScale={1}
            maxScale={maxZoomLevel}
            centerOnInit={true}
            wheel={{ step: 0.1 }}
            doubleClick={{ step: 1 }}
            panning={{ disabled: isZooming }}
            pinch={{ disabled: false }}
            zoomAnimation={{ disabled: false, size: 0.5 }}
            onZoomChange={(ref) => {
              setCurrentZoom(ref.state.scale);
              setIsZooming(ref.state.scale > 1);
            }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <TransformComponent>
                <img
                  src={mainImage}
                  alt="Large View"
                  onClick={() =>
                    handleZoomToggle(zoomIn, zoomOut, resetTransform)
                  }
                  style={{
                    cursor: isZooming ? "default" : "zoom-in",
                    minHeight: isMobile ? "495px" : "745px",
                  }}
                />
              </TransformComponent>
            )}
          </TransformWrapper>

          {/* Navigation Buttons */}
          <button className="prev-arrow" onClick={handlePrevThumbnail}>
            <img src="/images/back-arrow.png" alt="Previous" />
          </button>
          <button className="next-arrow" onClick={handleNextThumbnail}>
            <img src="/images/next-arrow.png" alt="Next" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsImageBlock;
